import React, { ReactElement } from 'react';
import { Step, useStore } from '../../../lib/store';
import Frequency from '../Frequency';
import Amount from '../Amount';
import PaymentProvider from '../PaymentProvider';
import Email from '../Email';
import Details from '../Details';
import Newsletter from '../Newsletter';
import Summary from '../Summary';
import {
  Button,
  Container,
  Dialog,
  DialogContent,
  makeStyles,
  createStyles,
  Grid,
  IconButton,
  DialogTitle,
  useScrollTrigger,
  Box,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { compact } from 'lodash';

const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      height: 50,
    },
    dialogTitle: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    dialogContent: {
      backgroundColor: theme.palette.grey[100],
      paddingLeft: 0,
      paddingRight: 0,
    },
    bottomSheet: {
      borderTop: `1px solid ${theme.palette.divider}`,
      backgroundColor: theme.palette.background.paper,
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      padding: theme.spacing(2),
      transition: theme.transitions.create('transform'),
      zIndex: 1,
    },
    bottomSheetHidden: {
      transform: 'translateY(80px)',
    },
  }),
);

const Form = (): ReactElement => {
  const trigger = useScrollTrigger({
    threshold: 250,
    disableHysteresis: true,
  });
  const classes = useStyles();
  const { state, dispatch } = useStore();

  const handleClick = () => {
    dispatch({ type: 'Show' });
  };

  const handleClose = () => {
    dispatch({ type: 'Reset' });
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={handleClick}
        size="large"
        fullWidth
        className={classes.button}
      >
        Give Gift
      </Button>
      <Dialog fullScreen open={state.currentStep !== Step.Hidden} onClose={handleClose} scroll="paper">
        <DialogTitle className={classes.dialogTitle}>
          <Container maxWidth="sm">
            <Grid alignItems="center" direction="row" container>
              <Grid item style={{ flex: 1 }}>
                Give a Gift to {state.appeal.name}
              </Grid>
              <Grid item>
                <IconButton onClick={handleClose} aria-label="Close">
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Container>
        </DialogTitle>
        <DialogContent dividers className={classes.dialogContent}>
          <Container maxWidth="sm">
            <Amount />
            <Frequency />
            <PaymentProvider />
            <Email />
            <Details />
            <Newsletter />
            <Summary />
          </Container>
        </DialogContent>
      </Dialog>
      {state.multiple === false && (
        <Box className={compact([classes.bottomSheet, trigger === false && classes.bottomSheetHidden]).join(' ')}>
          <Container maxWidth="sm">
            <Button
              variant="contained"
              color="primary"
              onClick={handleClick}
              size="large"
              fullWidth
              className={classes.button}
            >
              Give Gift to {state.appeal.name}
            </Button>
          </Container>
        </Box>
      )}
    </>
  );
};

export default Form;
