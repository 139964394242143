import React, { createContext, Dispatch, ReactElement, useReducer } from 'react';
import { ReactNode } from 'react';
import { useContext } from 'react';

type PaymentProvider = {
  id: string;
  name: string;
  base: string;
  percentage: string;
  recurringGift: boolean;
  singleGift: boolean;
};

type Frequency = {
  name: string;
  frequency: string;
};

export enum Step {
  Hidden,
  Amount,
  Frequency,
  PaymentProvider,
  Email,
  Details,
  Newsletter,
  Summary,
}

export type State = {
  multiple: boolean;
  donation?: {
    paymentProvider: PaymentProvider;
    amount: string;
    frequency: Frequency;
    receiveNewsletter: boolean;
  };
  appeal: {
    id: string;
    name: string;
    recurring: boolean;
  };
  paymentProviders: PaymentProvider[];
  frequencies: Frequency[];
  user: {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    region: string;
    countryCode: string;
    postalCode: string;
    receiveNewsletter: boolean;
  };
  organization: {
    name: string;
    imageUrl: string;
    primaryColor: string;
  };
  links: {
    webUrl: string;
    rootUrl: string;
    privacyUrl: string;
    refundPolicyUrl?: string;
  };
  authenticityToken: string;
  currentStep: Step;
  userExists: boolean;
  countries: {
    [key: string]: string;
  };
};

type Action =
  | {
      type: 'SetFrequency';
      frequency: Frequency;
    }
  | {
      type: 'SetAmount';
      amount: string;
    }
  | {
      type: 'SetPaymentProvider';
      paymentProvider: PaymentProvider;
    }
  | {
      type: 'SetEmail';
      email: string;
      userExists: boolean;
    }
  | {
      type: 'SetDetails';
      values: {
        firstName: string;
        lastName: string;
        phoneNumber: string;
        addressLine1: string;
        addressLine2: string;
        city: string;
        region: string;
        countryCode: string;
        postalCode: string;
      };
    }
  | {
      type: 'SetNewsletter';
      organizationReceiveNewsletter: boolean;
      appealReceiveNewsletter: boolean;
    }
  | {
      type: 'Reset';
    }
  | {
      type: 'Show';
    }
  | {
      type: 'SetStep';
      step: Exclude<Step, Step.Hidden>;
    };

type Store = { state: State; dispatch: Dispatch<Action> };

const store = createContext<Store>({} as Store);
const { Provider } = store;

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SetAmount':
      return {
        ...state,
        donation: { ...state.donation, amount: action.amount },
        currentStep: state.appeal.recurring ? Step.Frequency : Step.PaymentProvider,
      };
    case 'SetFrequency':
      return {
        ...state,
        donation: { ...state.donation, frequency: action.frequency },
        currentStep: Step.PaymentProvider,
      };
    case 'SetPaymentProvider':
      return {
        ...state,
        donation: { ...state.donation, paymentProvider: action.paymentProvider },
        currentStep: state.userExists ? Step.Newsletter : Step.Email,
      };
    case 'SetEmail':
      return {
        ...state,
        user: { ...state.user, email: action.email },
        userExists: action.userExists,
        currentStep: action.userExists ? Step.Newsletter : Step.Details,
      };
    case 'SetDetails':
      return {
        ...state,
        user: { ...state.user, ...action.values },
        currentStep: Step.Newsletter,
      };
    case 'SetNewsletter':
      return {
        ...state,
        user: { ...state.user, receiveNewsletter: action.organizationReceiveNewsletter },
        donation: { ...state.donation, receiveNewsletter: action.appealReceiveNewsletter },
        currentStep: Step.Summary,
      };
    case 'Reset':
      return {
        ...state,
        donation: {
          paymentProvider: undefined,
          amount: '0',
          frequency: undefined,
          receiveNewsletter: true,
        },
        currentStep: Step.Hidden,
      };
    case 'Show':
      if (state.currentStep === Step.Hidden) {
        return {
          ...state,
          currentStep: Step.Amount,
        };
      } else {
        return state;
      }
    case 'SetStep':
      if (state.currentStep >= action.step) {
        return {
          ...state,
          currentStep: action.step,
        };
      }
    default:
      throw new Error();
  }
};

type StoreProviderProps = {
  initialState: State;
  children: ReactNode;
};

const StoreProvider = ({ initialState, children }: StoreProviderProps): ReactElement => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    donation: {
      paymentProvider: undefined,
      amount: '0',
      frequency: undefined,
      receiveNewsletter: true,
      ...initialState.donation,
    },
    currentStep: initialState.currentStep || Step.Hidden,
  });

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

const useStore = (): Store => useContext(store);

export { store, StoreProvider, useStore, reducer };
